import {
  inject
} from 'aurelia-framework';
import {
  Router
} from "aurelia-router";
import {
  apiConfig
} from "configs";
import authService from 'services/authService';

@inject(Router)
export class LogoutPage {

  constructor(_Router) {
    this.router = _Router;
  }

  attached() {
    authService.logout();

    this.router.reset();
    this.router.deactivate();

    let urlParams = {
      redirectUrl: window.location.origin
    }
    window.location.replace(`${apiConfig.fonixAccount}#/signout/${window.btoa(JSON.stringify(urlParams))}`);
  }
}
