/** @format */

import { DateTimeUtils } from '@fonix/web-utils';
import { DialogService } from 'aurelia-dialog';
import { bindable } from 'aurelia-framework';

import './diagnostic-details.scss';

import diagnosticsService from 'services/api/diagnosticsService';
import telemetryService from 'services/api/telemetryService';

import { DiagnosticDeviceDetails } from './diagnostic-device-details/diagnostic-device-details';
import { PromptDialog } from 'components/prompt-dialog/prompt-dialog';

export class DiagnosticDetails {
  @bindable route;
  @bindable sortProperties;

  static inject() {
    return [DialogService];
  }

  constructor(_DialogService) {
    this.dialogService = _DialogService;

    this.diagnosticsService = diagnosticsService;
    this.telemetryService = telemetryService;

    this.device = null;
    this.telemetry = null;
    this.route = {};

    this.gridHeaders = [
      { label: 'position', sortable: false },
      { label: 'timestamp', sortable: true },
      { label: 'created', sortable: true },
      {
        label: 'speed (km/h)',
        sortable: false,
        sortField: 'speed',
        sortType: 'number'
      },
      { label: 'heading' },
      { label: 'firstEvent' },
      {
        label: 'Power (V)',
        sortable: false,
        sortField: 'externalVoltage',
        sortType: 'number'
      },
      { label: 'DI' }
    ];

    this.gridColumns = [
      { type: 'linkurl', field: 'latlngLink', labelField: 'latlng' },
      { type: 'date', field: 'gpsLocal', format: 'L LT' },
      { type: 'date', field: 'createdLocal', format: 'L LT' },
      { type: 'text', field: 'speed' },
      { type: 'text', field: 'headingCardinal' },
      { type: 'text', field: 'firstEventId' },
      { type: 'number', field: 'externalVoltage', format: '0.0' },
      { type: 'diports', field: 'digitalInputs' }
    ];

    this.sortProperties = {
      field: 'created',
      reverse: true
    }

    this.onDateFilterChanged = this.onDateFilterChanged.bind(this);
    this.canRefresh = true;
  }

  activate(params, cfg) {
    this.route = cfg;
    this.loadData(params.uuid);
  }

  loadData(uuId) {
    this.qDevice = !this.device ? this.loadDevice(uuId) : Promise.resolve(true);
  }

  loadDevice(uuId) {
    return this.diagnosticsService
      .getDevices({ uuId }, null, false)
      .then(devices => {
        this.device = devices[0];
      });
  }

  loadTelemetry(dtfrom, dtto) {
    if (this.device) {
      dtfrom = dtfrom || DateTimeUtils.startOf(new Date());
      dtto = dtto || new Date();

      this.loadingTelemetry = true;
      this.telemetryService
      .getPositions(this.device.assetId, dtfrom, dtto, false, false, true)
      .then((telemetry = []) => {
          this.telemetry = telemetry.reverse();
          this.loadingTelemetry = false;
        });
    }
  }

  refresh() {
    this.loadDevice(this.device.uuId);
    this.loadTelemetry();
  }

  onDeviceClick() {
    this.dialogService.open({
      viewModel: DiagnosticDeviceDetails,
      model: this.device.id
    });
  }

  onDateFilterChanged(dtfrom, dtto) {
    if (this.qDevice) {
      this.qDevice.then(() => {
        this.canRefresh = dtfrom >= DateTimeUtils.startOf(new Date());
        this.loadTelemetry(dtfrom, dtto);
      });
    }
  }

  assignToMeAction() {
    this.assignAction('assignToMe');
  }

  resolveAction() {
    this.openPrompt('mark_as_resolved', true, null, 'resolve').then(confirm => {
      if (!confirm) return;
      this.assignAction('resolve');
    });
  }

  openPrompt(title, question, message, confirm) {
    return new Promise(resolve => {
      this.dialogService
        .open({
          viewModel: PromptDialog,
          model: {
            title,
            question,
            message,
            confirm
          }
        })
        .whenClosed(result => {
          resolve(!result.wasCancelled);
        });
    });
  }

  assignAction(action) {
    return this.diagnosticsService
      .assignAction(this.device.id, action)
      .then(device => {
        this.device = device;
      });
  }
}
