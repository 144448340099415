import { observable, bindable } from 'aurelia-framework';

import { TranslationService } from 'services/translationService';
import { DialogService } from 'aurelia-dialog';
import filesService from '../../services/api/filesService';
import tachographService from 'services/api/tachographService';

import './files.scss';
import messagingService from '../../services/api/messagingService';
import assetsService from '../../services/api/assetsService';
import driversService from '../../services/api/driversService';
import uiService from '../../services/uiService';

const TO_LIST = a => {
  a.cssClass = `unprocessed`;
  a.icon = `fi-download`;
  return a;
};

export class Files {
  @observable typeSelected;
  @bindable isScrolling;

  static inject() {
    return [DialogService, TranslationService];
  }

  constructor(_DialogService, _TranslationService) {
    this.date = new Date().toISOString();
    this.loadingForms = false;
    this.formsArray = [];

    this.translations = _TranslationService;
    this.dialogService = _DialogService;
    this.tachographService = tachographService;
    this.filesService = filesService;
    this.uiService = uiService;

    this.assets;
    this.drivers;

    this.dates = null;
    this.typeSelected = 'tacho';

    this.sortProperties = {
      field: 'received',
      reverse: true,
    }

    this.prompt = true;
    this.errorMessage = {
      message: '',
      button: {
        label: '',
        action: ''
      },
      mediaSettings: null
    };
    this.loading = false;
    this.noResults = false;

    this.downloads = [];
    this.list = [];

    this.filters = null;
    this.filterAssets = [];
    this.filterDrivers = [];
    this.isScrolling = false;
    this.files = [];

    this.onItemSelected = this.onItemSelected.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
  }

  attached() {
    this.error = null;
  }

  typeSelectedChanged() {
    this.filters = null;
    this.gridHeaders = [];
    this.gridColumns = [];

    switch (this.typeSelected) {
      case 'tacho':
        this.gridHeaders.push(
          { label: 'asset', sortable: true, sortField: 'asset' },
          { label: 'Period Start', sortable: true, sortField: 'dataPeriodBegin' },
          { label: 'Period End', sortable: true, sortField: 'dataPeriodEnd' },
        );
        this.gridColumns.push(
          { type: 'text', field: 'asset' },
          { type: 'date', field: 'dataPeriodBegin', format: 'DateTime' },
          { type: 'date', field: 'dataPeriodEnd', format: 'DateTime' },
        );
        break;
      case 'driverCard':
        this.gridHeaders.push(
          { label: 'driver', sortable: true, sortField: 'driver' },
          { label: 'card id', sortable: true, sortField: 'driverCardId' },
          { label: 'file timestamp', sortable: true, sortField: 'fileTimestamp' }
        );
        this.gridColumns.push(
          { type: 'text', field: 'driver' },
          { type: 'text', field: 'driverCardId' },
          { type: 'date', field: 'fileTimestamp', format: 'DateTime' }
        );
        break;
      case 'forms':
        this.gridHeaders.push(
          { label: 'name', sortable: true, sortField: 'title' },
          { label: 'asset', sortable: true, sortField: 'asset' },
          { label: 'driver', sortable: true, sortField: 'driver' }
        );
        this.gridColumns.push(
          { type: 'text', field: 'name' },
          { type: 'text', field: 'asset' },
          { type: 'text', field: 'driver' }
        );
        break;
    }

    this.gridHeaders.push(
      { label: 'received', reverse: true, sortable: true, sortField: 'timestamp' },
      { label: '', sortable: false, cssClass: 'image' }
    )
    this.gridColumns.push(
      { type: 'date', field: 'timestamp', format: 'DateTime' },
      { type: 'icon', field: 'icon', clickable: true }
    );
  }

  async loadDownloads(dtfrom, dtto) {
    if (this.typeSelected === "forms" && this.assets !== undefined && this.drivers !== undefined) {
      this.loading = false;
    } else {
      this.loading = true;
      this.loadingForms = false;
    }
    this.noResults = false;
    if (this.typeSelected === "forms") {
      this.files = await filesService.getFiles(this.date, null, null);
      if (this.files === undefined || this.files.status === 204) {
        this.loadingForms =  Object.freeze(false);
        this.errorMessage.button.label = '';
        this.errorMessage.button.action = '';
        this.prompt = false;
      } else {
        this.files = this.files.reverse();
        this.date = this.files[this.files.length - 1].timestamp;
        if (this.assets === undefined && this.drivers === undefined) {
          this.loading = true;
          this.assets = await assetsService.getAll(false);
          this.drivers = await driversService.getDrivers();
        }
        if (this.typeSelected === "forms" && this.files.status !== 204) {
        this.files.forEach(form => {
          this.formsArray.push(form);
        });

          this.formsArray.forEach(form => {
            this.assets.forEach(asset => {
              if (form.tags.assetId == asset.id.toString()) {
                form.asset = asset.name;
                if (!this.filterAssets.includes(form.asset)) {
                  this.filterAssets.push(form.asset);
                }
              }
            });
            this.drivers.forEach(driver => {
              if (form.tags.driverId == driver.id.toString()) {
                form.driver = driver.name;
                if (!this.filterDrivers.includes(form.driver)) {
                  this.filterDrivers.push(form.driver);
                }
              }
            });
            form.name =
              form.metadata === undefined ? form.id :
                form.metadata.title !== undefined ? form.metadata.title : form.metadata.filename;
          });
          this.downloads = this.formsArray.map(TO_LIST);
          this.list = this.formsArray.map(TO_LIST);
        }
      }
    } else {
      this.loadingForms = false;
      this.files = await this.tachographService.getFiles(dtfrom, dtto, this.typeSelected);
      this.downloads = this.files.map(TO_LIST);
    }

    this.filterByAsset(this.filterAssets);
    this.filterByDriver(this.filterDrivers);
    this.loading = false;
    this.noResults = this.downloads && this.downloads.length === 0;
  }

  onItemSelected(row, index, column) {
    if (this.typeSelected === 'forms' && column.field === 'icon') {
      messagingService.downloadById(row);
    } else if (column.field === 'icon') {
      let link = this.tachographService.encodeMediaIdWithToken(row.id)
      window.open(link);
    }
  }

  onFilterChanged(filters = {}) {
    if (filters.driver) {
      this.filterByDriver(filters.driver);
    }
    if (filters.asset) {
      this.filterByAsset(filters.asset);
    }
    if (filters.dates) {
      this.filterbyDate(filters.dates);
    }
  }

  filterByAsset(asset = []) {
    let filtered = this.downloads;

    this.filterAssets = asset;
    if (asset.length) {
      filtered = this.downloads.filter(x => {
        return asset.indexOf(x.asset) > -1;
      });
    }
    this.list = filtered;
  }

  filterByDriver(driver = []) {
    let filtered = this.downloads;

    this.filterDrivers = driver;
    if (driver.length) {
      filtered = this.downloads.filter(x => {
        return driver.indexOf(x.driver) > -1;
      });
    }
    this.list = filtered;
  }

  filterbyDate(dates = {}) {
    if (dates.dtfrom && dates.dtto) {
      this.dates = dates;
      this.loadDownloads(dates.dtfrom, dates.dtto);
    }
  }

  selectType = (type) => {
    this.list = [];
    this.downloads = [];
    this.formsArray = [];
    this.loadingForms = true;
    this.date = new Date().toISOString();
    if (type === "forms") {
      const scrollElement = document.querySelector("#scroll");
      scrollElement.addEventListener("scroll", event => {
        if (scrollElement.offsetHeight + scrollElement.scrollTop >= scrollElement.scrollHeight) {
          this.loadDownloads(this.dates.dtfrom, this.dates.dtto);
          this.loadingForms = false;
        } else if (scrollElement.scrollTop > 0 && this.files) {
          this.loadingForms = true;
        }
      }, { passive: true });
    }
    this.typeSelected = type;
    this.loadDownloads(this.dates.dtfrom, this.dates.dtto);
  }
}
