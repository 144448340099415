import {
  bindable,
  inject
} from 'aurelia-framework';

import './diagnostics-summary.scss';
export class DiagnosticsSummary {
  @bindable onStatClick;
  @bindable stats;

  constructor(_DiagnosticsService) {
    this.stats = {
      total: '-'
    };
  }
}
