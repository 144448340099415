/** @format */

import {
  DialogController
} from 'aurelia-dialog';
import diagnosticsService from 'services/api/diagnosticsService';
import assetsService, {
  InactivityUnits
} from 'services/api/assetsService';

import './diagnostics-device-details.scss';


export class DiagnosticDeviceDetails {
  static inject() {
    return [DialogController];
  }
  constructor(_DialogController) {
    this.dialogController = _DialogController;
    //
    this.diagnosticsService = diagnosticsService;
    this.inactivityUnits = InactivityUnits;

    this.device = null;
    this.loading = false;
    this.properties = [];
    this.showSymmetrick = false;
  }

  activate(id) {
    this.loadData(id);
  }

  loadData(id) {
    this.loading = true;
    this.diagnosticsService.getDevice(id).then(device => {
      for (let prop in device.properties) {
        if (device.properties.hasOwnProperty(prop)) {
          this.properties.push({ [prop]: device.properties[prop] });
        }
      }
      this.device = device;
      this.loading = false;
    });
  }

  getProperty(obj, type) {
    switch (type) {
      case 'key':
        return Object.keys(obj)[0] + ':';
        break;
      case 'value':
        return Object.values(obj)[0];
        break;
    }
  }

  onClose() {
    this.dialogController.ok();
  }
}
