import { aurelia,inject } from 'aurelia-framework';
import PLATFORM from 'aurelia-pal';

export class DiagnosticRouter {
  configureRouter(config,router) {
    this.router = router;
    this.routes = [
      {
        route: [''],
        name: 'diagnostics',
        moduleId: PLATFORM.moduleName('./pages/diagnostics/diagnostics'),
        title: '',
        nav: true
      },
      {
        route: ':uuid',
        name: 'diagnostic-details',
        moduleId: PLATFORM.moduleName('./pages/diagnostic-details/diagnostic-details'),
        title: 'Diagnostic details',
        nav: false
      }
    ]
    config.map(this.routes);
  }

  goTo(route,type) {
    this.router.navigateToRoute(route);
  }
}
