/** @format */

import { bindable, inject } from "aurelia-framework";
import { TranslationService } from "services/translationService";

const ID_ALL = 0;

@inject(TranslationService)
export class FilesFilters {
  @bindable onFilterChanged;
  @bindable items;
  @bindable typeSelected;

  constructor(_TranslationService) {
    this.ts = _TranslationService;

    this.driver = [
      {
        id: "all",
        value: ID_ALL,
        name: this.ts.getCap("all"),
        disabled: true,
        default: true,
        hide: true
      }
    ];

    this.asset = [
      {
        id: "all",
        value: ID_ALL,
        name: this.ts.getCap("all"),
        disabled: true,
        default: true,
        hide: true
      }
    ];

    this.driverForms = this.driver;
    this.assetForms = this.asset;

    this.onDateFilterChanged = this.onDateFilterChanged.bind(this);
    this.onAssetTypeChanged = this.onAssetTypeChanged.bind(this);
    this.onDriverTypeChanged = this.onDriverTypeChanged.bind(this);
  }

  itemsChanged() {
    if (this.items != undefined &&
      this.items.length > 0 &&
      this.items[0].hasOwnProperty("metadata")) {
      this.items.forEach(item => {
          this.assetForms.push({
            id: item.asset,
            value: item.asset,
            name: this.ts.getCap(item.asset)
          });
          this.driverForms.push({
            id: item.driver,
            value: item.driver,
            name: this.ts.getCap(item.driver)
          });
      });
      
    this.assetForms = this.setUniqueValues(this.assetForms);
    this.driverForms = this.setUniqueValues(this.driverForms);
    } else {
      this.items.forEach(item => {
        if (item.hasOwnProperty("asset")) {
          this.asset.push({
            id: item.asset,
            value: item.asset,
            name: this.ts.getCap(item.asset)
          });
        } else if (item.hasOwnProperty("driver")) {
          this.driver.push({
            id: item.driver,
            value: item.driver,
            name: this.ts.getCap(item.driver)
          });
        }
      });
      this.asset = this.setUniqueValues(this.asset);
      this.driver = this.setUniqueValues(this.driver);
    }
  }

  setUniqueValues(filter) {
    const seen = new Set();
    return filter.filter(el => {
      const duplicate = seen.has(el.id);
      seen.add(el.id);
      return !duplicate;
    });
  }

  onAssetTypeChanged(options) {
    if (this.onFilterChanged) {
      let filter = options.map(x => {
        return x.value;
      });
      this.onFilterChanged({ asset: filter });
    }
  }

  onDriverTypeChanged(options) {
    if (this.onFilterChanged) {
      let filter = options.map(x => {
        return x.value;
      });
      this.onFilterChanged({ driver: filter });
    }
  }

  onDateFilterChanged(dtfrom, dtto) {
    if (this.onFilterChanged && dtfrom && dtto) {
      this.onFilterChanged({ dates: { dtfrom, dtto } });
    }
  }
}
