import { bindable,inject } from 'aurelia-framework';

import './diagnostics-grid.scss';

export class DiagnosticsGrid {

  @bindable onDeviceSelected;
  @bindable devices;
  @bindable loading;
  @bindable route;
  @bindable sortProperties;

  @bindable onScrollEnd;

  constructor() {
    this.loading = false;
    this.gridHeaders = [
      { label: '' },
      { label: 'UUId',sortable: true, sortField: 'uuId' },
      { label: 'name',sortable: true, sortField: 'assetName' },
      { label: 'account',sortable: true, sortField: 'accountName' },
      { label: 'reseller',sortable: true, sortField: 'resellerName' },
      { label: 'model',sortable: true, sortField: 'fullModel' },
      { label: 'updated', sortable: true, sortField: 'updated' },
    ];
    this.gridColumns = [
      { type: 'icon',field: 'icon' },
      { type: 'text',field: 'uuId' },
      { type: 'text',field: 'assetName' },
      { type: 'text',field: 'accountName' },
      { type: 'text',field: 'resellerName' },
      { type: 'text',field: 'fullModel' },
      { type: 'text',field: 'updatedCalendar' },
    ]

    this.sortProperties = {
      field: 'uuId',
      reverse: false
    }

    this.devices = [];
    this.onItemSelected = this.onItemSelected.bind(this);
  }

  onItemSelected(device) {
    //
    if (this.onDeviceSelected) {
      this.onDeviceSelected(device);
    }
  }
}
