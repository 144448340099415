import {
  bindable,
  computedFrom
} from 'aurelia-framework';
import userService from 'services/api/userService';
import './diagnostics-filter.scss';

export class DiagnosticsFilter {

  @bindable filter;
  @bindable onFilterApply;

  constructor() {
    this.userService = userService;

    this.value = '';
    this.selectedAccount = null;
    this.accounts = [];
    this.accountsValue = '';
    this.onAccountsChange = this.onAccountsChange.bind(this);
    this.onAccountSelect = this.onAccountSelect.bind(this);
    this.onApply = this.onApply.bind(this);
  }

  @computedFrom('value', 'selectedAccount')
  get enableApply() {
    return this.value.length >= 3 || !!this.selectedAccount;
  }

  onApply() {
    if (this.enableApply) {
      this.onFilterApply(this.filterToObject());
    }
  }

  filterChanged(value) {
    if (!value) {
      this.value = '';
      this.selectedAccount = null;
      this.accountsValue = '';
    }
  }

  filterToObject() {
    var result = {};
    if (this.value) {
      result.text = this.value
    }
    if (this.selectedAccount) {
      result.accountid = this.selectedAccount.id
    }

    return result;
  }

  onAccountSelect(account) {
    if (account) {
      this.accountsValue = account.name;
      this.selectedAccount = account;
    } else {
      this.selectedAccount = null;
      this.accountsValue = '';
    }
  }

  onAccountsChange(value) {
    if (!value || value.length < 3) {
      this.selectedAccount = null;
      return;
    }

    if (this.selectedAccount && this.selectedAccount.name == value) {
      this.accounts = [];
      return;
    }

    this.userService.searchAccounts(value).then(acc => {
      this.accounts = (acc || []).slice(0, 6);
    })
  }
}
