/** @format */

import diagnosticsService from 'services/api/diagnosticsService';

import uiService from 'services/uiService';
import {
  Router
} from 'aurelia-router';
import {
  bindable,
  observable
} from 'aurelia-framework';
import './diagnostics.scss';

export class Diagnostics {
  @observable activeState;
  @bindable route;

  static inject() {
    return [Router];
  }

  constructor(_Router) {
    //
    this.diagnosticsService = diagnosticsService;
    this.uiService = uiService;

    this.router = _Router;
    this.route = {};
    this.summary = null;
    this.loadingSummary = true;
    this.devices = null;
    this.loading = false;

    this.filter = null;
    this.activeState = null;
    this.page = 1;
    this.pageCount = 1;

    this.sortProperties = {
      field: 'uuId',
      reverse: false
    }

    this.onStatClick = this.onStatClick.bind(this);
    this.onFilterApply = this.onFilterApply.bind(this);
    this.onDeviceSelected = this.onDeviceSelected.bind(this);

    this.onGridScrollEnd = this.onGridScrollEnd.bind(this);
  }

  activate(params, Config) {
    this.route = Config;
  }

  attached() {
    this.tableEl = this.gridEl.querySelector('.advanced-grid-component');

    this.readState();
    this.loadSummary();
  }

  onStatClick(stat) {
    this.activeState = stat
    let actions = {
      nocomm: this.diagnosticsService.getNotCommunicating,
      delayed: this.diagnosticsService.getDelayedDevices,
      assigned: this.diagnosticsService.getAssigned
    };

    if (actions[stat]) {
      this.filter = null;
      this.page = 1;
      this.pageCount = 1;
      this.loading = true;
      actions[stat].call(this.diagnosticsService).then(res => {
        this.devicesResponse(res);
        this.loadSummary();

        this.loading = false;
      });
    }
  }

  activeStateChanged(state){
    switch(this.activeState) {
      case 'nocomm':
      this.sortProperties = {
        field: 'updated',
        reverse: true
      }
      break;
      case 'delayed':
      this.sortProperties = {
        field: 'updated',
        reverse: true
      }
      break;
      default:
      this.sortProperties = {
        field: 'uuId',
        reverse: false
      }
      break;
    }
  }

  loadSummary() {
    this.loadingSummary = true;
    this.diagnosticsService.getSummary().then(summary => {
      this.summary = summary;
      this.loadingSummary = false;
    });
  }

  loadDevices() {
    this.loading = this.page < 2;

    this.diagnosticsService.searchDevices(this.filter, this.page).then(res => {
      this.devicesResponse(res);
      this.loading = false;
    });
  }

  devicesResponse({
    devices = [],
    page,
    pageCount
  }) {
    this.page = page;
    this.pageCount = pageCount;

    if (page > 1) this.devices.push(...devices);
    else this.devices = devices;
  }

  onFilterApply(filter) {
    this.filter = filter;
    this.activeState = null;
    this.page = 1;
    this.pageCount = 1;

    this.loadDevices();
  }

  onDeviceSelected(device) {
    if (device && device.id) {
      this.devices.forEach(d => (d.selected = d.id === device.id));
      this.saveState();
      this.goToDetails(device);
    }
  }

  goToDetails(device) {
    let route = 'diagnostic-details';
    this.router.routes.find(x => x.name === route).settings.device = device;
    this.router.navigateToRoute(route, {
      uuid: device.uuId
    });
  }

  saveState() {
    //this are experiments that save a ui state across route/page changes
    //Maybe we should create child ui service that can store arbitrary view-model data/state so it can be read back
    //this woul ideally be handled by aurelia, but we maybe we can create a plugin?
    this.uiService.viewData.diagDevices = this.devices;
    this.uiService.viewData.diagScrollTop = this.tableEl.scrollTop;
    this.uiService.viewData.diagFilter = this.filter;
  }

  readState() {
    this.devices = this.uiService.viewData.diagDevices || [];
    let selected = this.devices.find(x => x.selected);
    let scrollTop = this.uiService.viewData.diagScrollTop;

    if (selected && this.tableEl && scrollTop) {
      setTimeout(() => (this.tableEl.scrollTop = scrollTop), 0);
    }

    this.filter = this.uiService.viewData.diagFilter;
  }

  onGridScrollEnd(scrollEnd) {
    if (this.loading) return;
    if (scrollEnd) {
      if (this.pageCount > 1 && this.page < this.pageCount) {
        this.page++;
        this.loadDevices();
      }
    }
  }
}
